import type { ErrorName } from '~/types/error.types'

let lastError: InstanceType<typeof BaseError>
let lastErrorTime: number

export function useCustomError(config: {
  error: InstanceType<typeof BaseError>
  cause?: InstanceType<typeof Error>
  data?: any
  reportToSentry: boolean
  reportToUser: boolean
}): InstanceType<typeof BaseError> {
  if (import.meta.client) {
    // Logging the error
    console.log(config.error.name, config.error.message, { cause: config.cause, data: config.data })

    // Preventing duplicate error reporting
    if (lastError?.name === config.error.name && Date.now() - lastErrorTime < 1000) return config.error
    lastError = config.error
    lastErrorTime = Date.now()

    // Reporting error to Sentry
    if (config.reportToSentry) {
      const { $Sentry } = useNuxtApp()
      if (config.cause) $Sentry.setContext('errorCause', config.cause)
      if (config.data) $Sentry.setContext('errorData', config.data)
      $Sentry.captureException(config.error)
    }

    // Informing user through a toast notification
    if (config.reportToUser) {
      const { addErrorNotification } = useNotifications()
      addErrorNotification({
        message: getErrorMessage(config.error.name as ErrorName, config.error.message)
      })
    }
  } else {
    console.log('useCustomError() inside SSR => not taken into account', config.error.name)
    console.log('useCustomError() error', config.error)
    // ? Something todo ?
  }

  return config.error
}